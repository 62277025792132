import axios from 'axios';
import { getGuestSession, isValidSession, Session } from './Session';
import { RetoolMenuGroup } from '../components/ui/retool/RetoolMenu';

const BASE_URL =  process.env.REACT_APP_API_URL || 'http://localhost:9099'; 
const LOGIN_URL = `${BASE_URL}/login`;
const STATUS_URL = `${BASE_URL}/status`;
const MENU_URL = `${BASE_URL}/retool/menu`;
const TOKEN_HEADER = 'x-justrpc-token';

type AxiosResponse = {
  headers:any;
};

export class Connection {
  static cleanSession() {
    localStorage.removeItem(TOKEN_HEADER);
  }

  static persistResponse(response:AxiosResponse) {
    localStorage.setItem(TOKEN_HEADER, response.headers[TOKEN_HEADER]);
  }

  static async login(nick:string, password:string):Promise<Session> {
    try {
      const response = await axios.post(LOGIN_URL, { nick, password });
      const session = isValidSession(response.data);
      this.persistResponse(response);
      return session;
    } catch (e) {
      throw new Error('Usuario o contraseña incorrecta');
    }
  }

  static async getMenus():Promise<Array<RetoolMenuGroup>> {
    try {
      const headers:{[key: string]:any} = {};
      headers[TOKEN_HEADER] = localStorage.getItem(TOKEN_HEADER);
      const response = await axios.get(MENU_URL, { headers });
      return response.data as Array<RetoolMenuGroup>;
    } catch (e) {
      return [];
    }
  }

  static async hasSession():Promise<Session> {
    try {
      const headers:{[key: string]:any} = {};
      headers[TOKEN_HEADER] = localStorage.getItem(TOKEN_HEADER);
      const response = await axios.post(STATUS_URL, {}, { headers });
      const session = isValidSession(response.data);
      return session;
    } catch (e) {
      return getGuestSession();
    }
  }
}
